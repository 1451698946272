<template>
  <div class="text-center">
    <img :src="require('@/assets/no-data.webp')" alt="Vazio" class="ml-8" />
    <h4>{{ label }}</h4>
  </div>
</template>

<script>
export default {
  name: 'NoData',

  props: {
    label: {
      default: 'Não há dados disponíveis',
    },
  },
};
</script>
