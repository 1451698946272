var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "text-center" }, [
    _c("img", {
      staticClass: "ml-8",
      attrs: { src: require("@/assets/no-data.webp"), alt: "Vazio" },
    }),
    _c("h4", [_vm._v(_vm._s(_vm.label))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }